// Packages:
import React, { useState, useEffect, memo, useCallback } from "react";
import {
  MenuItem,
  FormControl,
  Select,
  TextField,
  InputAdornment,
  Checkbox,
  ListItemText,
  styled,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import { MdCancel } from "react-icons/md";

import useInput from "../../../../lib/hooks/use-input";
import { SubFieldName, FieldName, VariableField, Variable } from "../styles";
import UserMessageCheckbox from "../../Components/UserMessageCheckbox";
import UserCheckbox from "../../Components/UserCheckbox";
import { CHANNEL, DEFAULT_OUTPUT, DEFAULT_OUTPUT_COMPANYNAME, DEFAULT_OUTPUT_GROUPID, DEFAULT_OUTPUT_NAME, DEFAULT_OUTPUT_PHONENUMBER, DEFAULT_OUTPUT_REQUESTER, DEFAULT_OUTPUT_SENDER, DEFAULT_OUTPUT_SUBJECT, DEFAULT_OUTPUT_FIRST_NAME, DEFAULT_OUTPUT_LAST_NAME ,STATUS, TICKETACTION, TICKETCHANNEL } from "./utils";
import BaseConfiguration from "../BaseConfiguration";
import AutocompleteGlobal from "../../Components/AutocompleteGlobal";

const StyledFormControl = styled(FormControl)`
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  max-height: 36px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

const StyledListItemText = styled(ListItemText)`
  font-size: 12px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

// Functions:
const parseCustomFields = (raw) =>
  raw
    .split(",")
    .map((val) => val.split(":")[1])
    .filter((val) => val !== undefined);

const TicketConfiguration = ({
  node,
  updateNode,
  deleteNode,
  settings,
  isEditable,
  currentRemoteWorkflow,
  setNodes
}) => {
  // Constants:
  const [extractCompliment, setExtractCompliment] = useState(node.extractCompliment??false);
  const [extractComplaint, setExtractComplaint] = useState(node.extractComplaint??false);

  const TICKET_FIELDS = {
    ZENDESK: [
      'tickets.id', 'tickets.status', 'tickets.description', 'tickets.subject',
      'tickets.type', 'tickets.priority', 'tickets.url', 'tickets.requester_id',
      'tickets.requester_email', 'tickets.submitter_id', 'tickets.submitter_email',
      'tickets.assignee_id', 'tickets.assignee_email', 'tickets.created_at',
      'tickets.updated_at', 'tickets.satisfaction_rating.comment',
      'tickets.satisfaction_rating.score',
      ...(extractComplaint ? ['tickets.user_complaint'] : []),
    ...(extractCompliment ? ['tickets.user_compliment'] : [])
    ],
    SALESFORCE: [
      'tickets.id', 'tickets.status', 'tickets.description', 'tickets.subject',
      'tickets.type', 'tickets.priority', 'tickets.url', 'tickets.requester_id',
      'tickets.requester_email', 'tickets.submitter_id', 'tickets.submitter_email',
      'tickets.assignee_id', 'tickets.assignee_email', 'tickets.created_at',
      'tickets.updated_at', 'tickets.satisfaction_rating.comment',
      'tickets.satisfaction_rating.score',
      ...(extractComplaint ? ['tickets.user_complaint'] : []),
      ...(extractCompliment ? ['tickets.user_compliment'] : [])
    ]
  };

  // State:
  const [ticketChannel, setTicketChannel] = useState(node.ticketChannel);
  const [ticketAction, setTicketAction] = useState(
    node.ticketAction ?? "CREATE"
  );
  const [subject, setSubject] = useState(node.subject??DEFAULT_OUTPUT_SUBJECT);
  const [commentTranscript,setCommentTranscript] = useState(node.commentTranscript??false);
  const [comment, setComment] = useState(node.comment ??"")
  const {
    value: days,
    setValue: setDays,
    bind: bindDays,
  } = useInput(node.time_period?.days ?? "");
  const {
    value: hours,
    setValue: setHours,
    bind: bindHours,
  } = useInput(node.time_period?.hours ?? "");
  const {
    value: mins,
    setValue: setMins,
    bind: bindMins,
  } = useInput(node.time_period?.mins ?? "");
  const [tags, setTags] = useState(node.tags ?? []);
  const {
    value: customFields,
    setValue: setCustomFields,
    bind: bindCustomFields,
  } = useInput(node.customFields ?? "");

  const [channel, setChannel] = useState(node.channel??"NONE");
  const [status, setStatus] = useState(node.status);
  const [searchFilter, setSearchFilter] = useState({
    status: node.searchFilter?.status || [],
  });
  const [tagInput, setTagInput] = useState("");
  const [customFieldsArray, setCustomFieldsArray] = useState([]);
  const [daysErrorText, setDaysErrorText] = useState("");
  const [hoursErrorText, setHoursErrorText] = useState("");
  const [minsErrorText, setMinsErrorText] = useState("");
  const [informationToExtract, setInformationToExtract] = useState(
    node.informationToExtract ?? []
  );
  const [macros, setMacros] = useState(node.macros ?? []);
  const [includedSearchTerms, setIncludedSearchTerms] = useState(
    node.includedSearchTerms ?? []
  );
  const [excludedSearchTerms, setExcludedSearchTerms] = useState(
    node.excludedSearchTerms ?? []
  );
  const [holdTimePeriod, setHoldTimePeriod] = useState(node.holdTimePeriod??{ days: 0,hours: 0,minutes: 0 });
  const [singleInformationToExtract, setSingleInformationToExtract] =
    useState("");
  const [singleMacro, setSingleMacro] = useState("");
  const [singleIncludedSearchTerm, setSingleIncludedSearchTerm] = useState("");
  const [singleExcludedSearchTerm, setSingleExcludedSearchTerm] = useState("");
  const [sendUserUpdate, setSendUserUpdate] = useState(node.sendUserUpdate??false);
  const [userMessage, setUserMessage] = useState(node.userMessage??"");
  const [ticket_id, setTicketId] = useState(node.ticket_id ??DEFAULT_OUTPUT);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields,setOutputFields]=useState([])
  const [dynamicDataExpected, setDynamicDataExpected] = useState(node.dynamicDataExpected??false);
  const [numberOfVariables, setNumberOfVariables] = useState( node.numberOfVariables ?? 0);
  const [dynamicData, setDynamicData] = useState(node.dynamicData??[]);
  const [sender,setSender] = useState(node.sender??DEFAULT_OUTPUT_SENDER)
  const [phoneNumber,setPhoneNumber] = useState(node.phoneNumber??DEFAULT_OUTPUT_PHONENUMBER)
  const [name,setName] = useState(node.name??DEFAULT_OUTPUT_NAME)
  const [companyName,setCompanyName]=useState(node.companyName??DEFAULT_OUTPUT_COMPANYNAME)
  const [group_id,setGroupId]=useState(node.group_id??DEFAULT_OUTPUT_GROUPID)
  const [requester,setRequester]=useState(node.requester??DEFAULT_OUTPUT_REQUESTER)
  const [firstName,setFirstName] = useState(node.firstName??DEFAULT_OUTPUT_FIRST_NAME)
  const [lastName,setLastName] = useState(node.lastName??DEFAULT_OUTPUT_LAST_NAME)

  // Effects:
  useEffect(() => {
    setTicketChannel(node.ticketChannel);
    setTicketAction(node.ticketAction);
    setSubject(node.subject??DEFAULT_OUTPUT_SUBJECT);
    setComment(node.comment??"");
    setCommentTranscript(node.commentTranscript??false)
    setCustomFields(node.customFields??"");
    setChannel(node.channel??"NONE");
    setStatus(node.status??"NONE");
    setSearchFilter(node.searchFilter??[])
    setDays(node?.time_period?.days??"");
    setHours(node?.time_period?.hours??"");
    setMins(node?.time_period?.mins??"");
    setTags(node.tags??[]);
    setMacros(node.macros??[]);
    setIncludedSearchTerms(node.includedSearchTerms??[]);
    setExcludedSearchTerms(node.excludedSearchTerms??[]);
    setInformationToExtract(node.informationToExtract??[]);
    setUserMessage(node.userMessage??"")
    setSendUserUpdate(node.sendUserUpdate??false)
    setExtractComplaint(node.extractComplaint??false)
    setExtractCompliment(node.extractCompliment??false)
    setTicketId(node.ticket_id??DEFAULT_OUTPUT)
    setHoldTimePeriod(node.holdTimePeriod??{ days: 0,hours: 0,minutes: 0 })
    setDynamicDataExpected(node.dynamicDataExpected??false);
    setNumberOfVariables(node.numberOfVariables ?? 0);
    setDynamicData(node.dynamicData ?? []);
    setSender(node.sender??DEFAULT_OUTPUT_SENDER)
    setPhoneNumber(node.phoneNumber??DEFAULT_OUTPUT_PHONENUMBER)
    setName(node.name??DEFAULT_OUTPUT_NAME)
    setCompanyName(node.companyName??DEFAULT_OUTPUT_COMPANYNAME)
    setGroupId(node.group_id??DEFAULT_OUTPUT_GROUPID)
    setRequester(node.requester??DEFAULT_OUTPUT_REQUESTER)    
    setFirstName(node.firstName??DEFAULT_OUTPUT_FIRST_NAME)
    setLastName(node.lastName??DEFAULT_OUTPUT_LAST_NAME)
  }, [
    node.ticketChannel,
    node.ticketAction,
    node.subject,
    node.comment,
    node.commentTranscript,
    node.customFields,
    node.channel,
    node.status,
    node.searchFilter,
    node.time_period?.days,
    node.time_period?.hours,
    node.time_period?.mins,
    node.tags,
    node.macros,
    node.includedSearchTerms,
    node.excludedSearchTerms,
    node.informationToExtract,
    node.userMessage,
    node.sendUserUpdate,
    node.extractComplaint,
    node.extractCompliment,
    node.ticket_id,
    node.holdTimePeriod,
    node.dynamicDataExpected,
    node.numberOfVariables,
    node.dynamicData,
    node.sender,
    node.phoneNumber,
    node.name,
    node.companyName,
    node.group_id,
    node.requester,
    node.firstName,
    node.lastName
  ]);
  useEffect(() => {
    updateNode(node.id, {
      ticketChannel,
      dynamicFields: [...TICKET_FIELDS[ticketChannel]??[],...dynamicFields],
      ticketAction,
      subject,
      ticket_id,
      comment,
      commentTranscript,
      tags,
      informationToExtract,
      macros,
      includedSearchTerms,
      excludedSearchTerms,
      customFields,
      channel,
      status,
      searchFilter,
      sendUserUpdate,
      userMessage,
      extractComplaint,
      extractCompliment,
      group_id,
      outputFields: [...outputFields, {id: node?.id,values: [...TICKET_FIELDS[ticketChannel]??[]]}], 
      time_period:{
        days,hours,mins
      },
      holdTimePeriod,
      dynamicDataExpected,
      numberOfVariables,
      dynamicData,
      sender,
      phoneNumber,
      name,
      companyName,
      requester,
      firstName,
      lastName
    });
  }, [
    dynamicFields,
    ticketChannel,
    ticketAction,
    subject,
    comment,
    commentTranscript,
    days,
    hours,
    mins,
    tags,
    informationToExtract,
    macros,
    includedSearchTerms,
    excludedSearchTerms,
    customFields,
    channel,
    status,
    searchFilter,
    sendUserUpdate,
    userMessage,
    extractComplaint,
    extractCompliment,
    ticket_id,
    holdTimePeriod,
    dynamicDataExpected,
    numberOfVariables,
    dynamicData,
    sender,
    phoneNumber,
    name,
    lastName,
    firstName,
    companyName,
    group_id,
    requester
  ]);

  useEffect(() => {
    if (customFields) setCustomFieldsArray(parseCustomFields(customFields));
  }, [customFields]);

  useEffect(() => {
    if (days < 0) setDaysErrorText("Number of days must be greater than 0");
    else setDaysErrorText("");
    if (hours < 0 || hours > 23)
      setHoursErrorText(
        "Number of hours must be greater than 0 and less than 24"
      );
    else setHoursErrorText("");
    if (mins < 0 || mins > 59)
      setMinsErrorText(
        "Number of minutes must be greater than 0 and less than 60"
      );
    else setMinsErrorText("");
  }, [days, hours, mins]);

  const handleTagValueChange = (e) => {
    const { value } = e.target;
    setTagInput(value);
  };

  const tagOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = tagInput.trim();
    if (
      (key === "," || key === "Enter") &&
      trimmedInput.length &&
      !tags?.includes(trimmedInput)
    ) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setTagInput("");
    }
  };

  const informationToExtractOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = singleInformationToExtract.trim();
    if (
      (key === "," || key === "Enter") &&
      trimmedInput.length &&
      !informationToExtract?.includes(trimmedInput)
    ) {
      e.preventDefault();
      setInformationToExtract((prevState) => [...prevState, trimmedInput]);
      setSingleInformationToExtract("");
    }
  };

  const macrosOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = singleMacro.trim();
    if (
      (key === "," || key === "Enter") &&
      trimmedInput.length &&
      !macros?.includes(trimmedInput)
    ) {
      e.preventDefault();
      setMacros((prevState) => [...prevState, trimmedInput]);
      setSingleMacro("");
    }
  };

  const includedSearchTermsOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = singleIncludedSearchTerm.trim();
    if (
      (key === "," || key === "Enter") &&
      trimmedInput.length &&
      !includedSearchTerms?.includes(trimmedInput)
    ) {
      e.preventDefault();
      setIncludedSearchTerms((prevState) => [...prevState, trimmedInput]);
      setSingleIncludedSearchTerm("");
    }
  };

  const excludedSearchTermsOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = singleExcludedSearchTerm.trim();
    if (
      (key === "," || key === "Enter") &&
      trimmedInput.length &&
      !excludedSearchTerms?.includes(trimmedInput)
    ) {
      e.preventDefault();
      setExcludedSearchTerms((prevState) => [...prevState, trimmedInput]);
      setSingleExcludedSearchTerm("");
    }
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  const deleteInformationToExtract = (index) => {
    setInformationToExtract((prevState) =>
      prevState?.filter((informationToExtract, i) => i !== index)
    );
  };

  const deleteMacros = (index) => {
    setMacros((prevState) => prevState?.filter((macros, i) => i !== index));
  };

  const deleteIncludedSearchTerms = (index) => {
    setIncludedSearchTerms((prevState) =>
      prevState?.filter((includedSearchTerms, i) => i !== index)
    );
  };

  const deleteExcludedSearchTerms = (index) => {
    setExcludedSearchTerms((prevState) =>
      prevState?.filter((excludedSearchTerms, i) => i !== index)
    );
  };

  const handleNumberOfInputChange = useCallback((e) => {
    const value = parseInt(e.target.value, 10) || 0;
    setNumberOfVariables(value);

    setDynamicData((prevDynamicData) => {
      const newDynamicData = [...prevDynamicData];
      if (value < newDynamicData.length) {
        newDynamicData.length = value;
      } else if (value > newDynamicData.length) {
        for (let i = newDynamicData.length + 1; i <= value; i++) {
          newDynamicData.push({
            key: null,
            value: { fieldName: null, value: null },
          });
        }
      }
      return newDynamicData;
    });
  }, []);
  const handleCheckboxChange = useCallback(() => {
    setDynamicDataExpected(!dynamicDataExpected);
    setNumberOfVariables(0);
    setDynamicData([]);
  }, [dynamicDataExpected]);

  const handleDataInputChange = useCallback(
    (index, field, value) => {
      if (field !== "key" && !dynamicFields.includes(value)) return;

      setDynamicData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          [field === "key" ? "key" : "value"]:
            field === "key"
              ? value
              : { ...newData[index].value, fieldName: value },
        };
        return newData;
      });
    },
    [dynamicFields]
  );

  const handleMultipleStatusChange = (e) => {
    const { target: { value } } = e;
  
    setSearchFilter((prevSearchFilter) => ({
      ...prevSearchFilter,
      status: typeof value === "string"
        ? value.split(",").map(s => s.trim().toLowerCase())
        : value.map(s => s.trim().toLowerCase()),
    }));
  };

  const handleInformationToExtractValueChange = (e) => {
    const { value } = e.target;
    setSingleInformationToExtract(value);
  };

  const handleMacrosValueChange = (e) => {
    const { value } = e.target;
    setSingleMacro(value);
  };

  const handleIncludedSearchTermsValueChange = (e) => {
    const { value } = e.target;
    setSingleIncludedSearchTerm(value);
  };

  const handleExcludedSearchTermsValueChange = (e) => {
    const { value } = e.target;
    setSingleExcludedSearchTerm(value);
  };

  const handleSelectChange = (value, type) => {
    setHoldTimePeriod(prev => ({ ...prev, [type]: parseInt(value, 10) }));
  };

  // Return:
  return (
    <BaseConfiguration setDynamicFields={setDynamicFields} node={node} isEditable={isEditable} deleteNode={deleteNode} outputFields={outputFields} setOutputFields={setOutputFields} setNodes={setNodes}>
      <FieldName>Ticket Channel</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="ticket-channel"
          disabled={!isEditable}
          value={ticketChannel}
          onChange={(e) => setTicketChannel(e.target.value)}
        >
          {Object.values(TICKETCHANNEL).map((channel, index) => (
            <StyledMenuItem key={index} value={channel}>
              {channel}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>

      <FieldName>Ticket Action</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="ticket-action"
          disabled={!isEditable}
          value={ticketAction}
          onChange={(e) => setTicketAction(e.target.value)}
        >
          {Object.values(TICKETACTION).map((action, index) => (
            <StyledMenuItem key={index} value={action}>
              {action}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>
      {(ticketAction === "FETCH NEW TICKETS"||ticketAction === "FETCH TICKET BY ID"||ticketAction === "CREATE"||ticketAction === "UPDATE")  && (
        <>
        {((ticketChannel !== TICKETCHANNEL.ZENDESK) && (ticketChannel !== TICKETCHANNEL.SALESFORCE)) && (
          <AutocompleteGlobal Title="SENDER" dynamicFields={dynamicFields} data={sender} DEFAULT_OUTPUT={DEFAULT_OUTPUT_SENDER} setData={setSender} isEditable={isEditable}/>
         )}
         {((ticketChannel === TICKETCHANNEL.ZENDESK)) && (
          <>
      <AutocompleteGlobal Title="Group Id" dynamicFields={dynamicFields} data={group_id} DEFAULT_OUTPUT={DEFAULT_OUTPUT_GROUPID} setData={setGroupId} isEditable={isEditable}/>
      {(ticketAction === "CREATE") && <AutocompleteGlobal Title="Requester" dynamicFields={dynamicFields} data={requester} DEFAULT_OUTPUT={DEFAULT_OUTPUT_REQUESTER} setData={setRequester} isEditable={isEditable}/>}
      </>   
    )}
        {((ticketChannel === TICKETCHANNEL.CLOSE) || (ticketChannel === TICKETCHANNEL.ZOHO)) && (
        <>
          <AutocompleteGlobal Title="Phone Number" dynamicFields={dynamicFields} data={phoneNumber} DEFAULT_OUTPUT={DEFAULT_OUTPUT_PHONENUMBER} setData={setPhoneNumber} isEditable={isEditable}/>
          <AutocompleteGlobal Title="Company Name" dynamicFields={dynamicFields} data={companyName} DEFAULT_OUTPUT={DEFAULT_OUTPUT_COMPANYNAME} setData={setCompanyName} isEditable={isEditable}/>
        </>)}  
        {((ticketChannel === TICKETCHANNEL.CLOSE)) && (
          <AutocompleteGlobal Title="Name" dynamicFields={dynamicFields} data={name} DEFAULT_OUTPUT={DEFAULT_OUTPUT_NAME} setData={setName} isEditable={isEditable}/>
          )}
        {((ticketChannel === TICKETCHANNEL.ZOHO)) && (
        <>
          <AutocompleteGlobal Title="First Name" dynamicFields={dynamicFields} data={firstName} DEFAULT_OUTPUT={DEFAULT_OUTPUT_FIRST_NAME} setData={setFirstName} isEditable={isEditable}/>
          <AutocompleteGlobal Title="Last Name" dynamicFields={dynamicFields} data={lastName} DEFAULT_OUTPUT={DEFAULT_OUTPUT_LAST_NAME} setData={setLastName} isEditable={isEditable}/>
        </>)}
          {ticketAction === "FETCH TICKET BY ID"  && (
            <>
            <AutocompleteGlobal Title="Ticket Id" dynamicFields={dynamicFields} data={setTicketId} DEFAULT_OUTPUT={DEFAULT_OUTPUT} setData={setTicketId} isEditable={isEditable}/>
          </>
        )}
         {ticketAction === "FETCH NEW TICKETS"  && (
          <>
          <FieldName>Time Period</FieldName>
          <StyledTextField
            id="ticket-time-period-days"
            variant="outlined"
            placeholder="0"
            disabled={!isEditable}
            error={daysErrorText.length === 0 ? false : true}
            label={daysErrorText.length === 0 ? null : "Error"}
            helperText={daysErrorText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">days</InputAdornment>
              ),
              readOnly: settings?.checked,
            }}
            type="number"
            {...bindDays}
            size="small"
            fullWidth
          />
          <StyledTextField
            id="ticket-time-period-hours"
            variant="outlined"
            disabled={!isEditable}
            placeholder="0"
            error={hoursErrorText.length === 0 ? false : true}
            label={hoursErrorText.length === 0 ? null : "Error"}
            helperText={hoursErrorText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">hours</InputAdornment>
              ),
              readOnly: settings?.checked,
            }}
            type="number"
            {...bindHours}
            size="small"
            fullWidth
          />
          <StyledTextField
            id="ticket-time-period-mins"
            variant="outlined"
            placeholder="0"
            disabled={!isEditable}
            error={minsErrorText.length === 0 ? false : true}
            label={minsErrorText.length === 0 ? null : "Error"}
            helperText={minsErrorText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">mins</InputAdornment>
              ),
              readOnly: settings?.checked,
            }}
            type="number"
            {...bindMins}
            size="small"
            fullWidth
          />

          <FieldName>Status</FieldName>
          <StyledFormControl size="small" fullWidth>
            <Select
              id="status"
              multiple

              value={searchFilter?.status.map((val)=>val.toUpperCase())??[]}
              disabled={!isEditable}
              onChange={(e) => handleMultipleStatusChange(e)}
              renderValue={(selected) => selected.join(", ")}
            >
              {Object.values(STATUS).map((status, index) => (
                <StyledMenuItem key={index} value={status}>
                  <Checkbox
                    size="small"
                    checked={searchFilter?.status?.indexOf(status.toLowerCase()) > -1} 
                  />
                  <StyledListItemText primary={status} />
                </StyledMenuItem>
              ))}
            </Select>
          </StyledFormControl>
          </>)}
        </>
      )}
      {(ticketAction === "CREATE" || ticketAction === "UPDATE") && (
        <>
          <AutocompleteGlobal Title={"Subject"} dynamicFields={dynamicFields} data={subject} DEFAULT_OUTPUT={DEFAULT_OUTPUT_SUBJECT} setData={setSubject} isEditable={isEditable}/>
          <div
              style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
            >
          <input
                type="checkbox"
                style={{ marginRight: "0.35rem" }}
                disabled={!isEditable}
                checked={commentTranscript}
                onChange={()=>setCommentTranscript(!commentTranscript)}
              />
              <div style={{ fontSize: "0.8rem" }}>send comment transcript</div>
              </div>
          <FieldName>Comment</FieldName>
            <StyledTextField
              id="message"
              variant="outlined"
              disabled={!isEditable}
              placeholder="Enter comment"
              value={comment}
              onChange={(e)=>setComment(e.target.value)}
              multiline
              rows={3}
              size="small"
              fullWidth
            />
                <div
              style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
            >
              <input
                type="checkbox"
                style={{ marginRight: "0.35rem" }}
                disabled={!isEditable}
                checked={dynamicDataExpected}
                onChange={handleCheckboxChange}
              />
              <div style={{ fontSize: "0.8rem" }}>dynamic fields expected:</div>
              <input
                type="number"
                min="0"
                disabled={!dynamicDataExpected || !isEditable}
                style={{
                  width: "3rem",
                  marginLeft: "0.25rem",
                }}
                value={numberOfVariables}
                onChange={handleNumberOfInputChange}
              />
            </div>
            {dynamicDataExpected && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {dynamicData.map((_, index) => (
            <div key={index} style={{ display: "flex", flexDirection: "row" }}>
              <StyledTextField
                type="text"
                placeholder="Variable"
                disabled={!isEditable}
                value={dynamicData[index].key}
                size="small"
                style={{ padding: 0 }}
                onChange={(e) =>
                  handleDataInputChange(index, "key", e.target.value)
                }
              />
              <Autocomplete
                options={dynamicFields}
                disabled={!isEditable}
                value={dynamicData[index].value.fieldName}
                fullWidth
                onChange={(_, value) =>
                  handleDataInputChange(index, "fieldName", value)
                }
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    placeholder="Value"
                    variant="outlined"
                    size="small"
                    style={{ padding: 0 }}
                  />
                )}
              />
            </div>
          ))}
        </div>
      )}
          <FieldName>Tags</FieldName>
          <VariableField>
            {tags?.map((tag, index) => (
              <Variable key={tag}>
                {tag}
                <MdCancel
                  style={{ marginLeft: "0.5rem", cursor: "pointer" }}
                  onClick={() => deleteTag(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              id="ticket-tags"
              variant="outlined"
              disabled={!isEditable}
              value={tagInput}
              placeholder="Enter a tag"
              onKeyDown={tagOnKeyDown}
              onChange={handleTagValueChange}
              size="small"
              fullWidth
            />
          </VariableField>

          <FieldName>Macro</FieldName>
          <VariableField>
            {macros?.map((macro, index) => (
              <Variable key={macro}>
                {macro}
                <MdCancel
                  style={{ marginLeft: "0.5rem", cursor: "pointer" }}
                  onClick={() => deleteMacros(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              type="text"
              variant="outlined"
              value={singleMacro}
              disabled={!isEditable}
              placeholder="Enter macros"
              onKeyDown={macrosOnKeyDown}
              onChange={handleMacrosValueChange}
              size="small"
              fullWidth
            />
          </VariableField>

          <FieldName>Custom Fields</FieldName>
          <StyledTextField
            id="ticket-custom-fields"
            variant="outlined"
            disabled={!isEditable}
            placeholder="key1:value1, key2:value2, etc."
            {...bindCustomFields}
            size="small"
            fullWidth
          />
          <VariableField style={{ marginTop: "0.5rem" }}>
            {customFieldsArray.map((customField) => (
              <Variable key={customField}>{customField}</Variable>
            ))}
          </VariableField>

          <FieldName>Channel</FieldName>
          <StyledFormControl size="small" fullWidth>
            <Select
              id="channel"
              disabled={!isEditable}
              value={channel}
              onChange={(e) => setChannel(e.target.value)}
            >
              {Object.values(CHANNEL).map((channel, index) => (
                <StyledMenuItem key={index} value={channel}>
                  {channel}
                </StyledMenuItem>
              ))}
            </Select>
          </StyledFormControl>

          <FieldName>Status</FieldName>
          <StyledFormControl size="small" fullWidth>
            <Select
              id="status"
              value={status?.toUpperCase()}
              disabled={!isEditable}
              onChange={(e) => setStatus(e.target.value.toLowerCase())}
            >
              {Object.values(STATUS).map((status, index) => (
                <StyledMenuItem key={index} value={status}>
                  {status}
                </StyledMenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </>
      )}
      {status=="hold"&&(<>
        <FieldName>Hold Period</FieldName>
          {['days', 'hours', 'minutes'].map(type => (
            <InputLabel  key={type} style={{ display:'flex',padding:2,alignContent:'center', justifyContent:'space-between'}} >
              {`${type}: `}
              <Select
                autoWidth={false}
                value={holdTimePeriod[type]}
                style={{marginLeft:10}}
                onChange={e => handleSelectChange(e.target.value, type)}
                size='small'
                sx={{ fontSize: 14,justifyContent:'center' }}
                
              >
                {Array.from({ length: type === 'holdDays' ? 31 : 60 }, (_, index) => (
                <StyledMenuItem  value={index} key={index}>{index}</StyledMenuItem>
                ))}
              </Select>
            </InputLabel>
          ))}
    </>)
}

      {ticketAction === "EXTRACT INFORMATION" && (
        <>
          <FieldName>Information to Extract</FieldName>
          <VariableField>
            {informationToExtract?.map((info, index) => (
              <Variable key={info}>
                {info}
                <MdCancel
                  style={{ marginLeft: "0.5rem", cursor: "pointer" }}
                  onClick={() => deleteInformationToExtract(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              type="text"
              variant="outlined"
              value={singleInformationToExtract}
              disabled={!isEditable}
              placeholder="Enter information to extract"
              onKeyDown={informationToExtractOnKeyDown}
              onChange={handleInformationToExtractValueChange}
              size="small"
              fullWidth
            />
          </VariableField>
        </>
      )}

      {ticketAction === "PREDICT TAGS" && (
        <>
          <FieldName>Search Terms</FieldName>

          <SubFieldName>Include</SubFieldName>
          <VariableField>
            {includedSearchTerms?.map((info, index) => (
              <Variable key={info}>
                {info}
                <MdCancel
                  style={{ marginLeft: "0.5rem", cursor: "pointer" }}
                  onClick={() => deleteIncludedSearchTerms(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              type="text"
              variant="outlined"
              value={singleIncludedSearchTerm}
              disabled={!isEditable}
              placeholder="Enter search terms to include"
              onKeyDown={includedSearchTermsOnKeyDown}
              onChange={handleIncludedSearchTermsValueChange}
              size="small"
              fullWidth
            />
          </VariableField>

          <SubFieldName>Exclude</SubFieldName>
          <VariableField>
            {excludedSearchTerms?.map((info, index) => (
              <Variable key={info}>
                {info}
                <MdCancel
                  style={{ marginLeft: "0.5rem", cursor: "pointer" }}
                  onClick={() => deleteExcludedSearchTerms(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              type="text"
              variant="outlined"
              disabled={!isEditable}
              value={singleExcludedSearchTerm}
              placeholder="Enter search terms to exclude"
              onKeyDown={excludedSearchTermsOnKeyDown}
              onChange={handleExcludedSearchTermsValueChange}
              size="small"
              fullWidth
            />
          </VariableField>

          <FieldName>Tags</FieldName>
          <VariableField>
            {tags?.map((tag, index) => (
              <Variable key={tag}>
                {tag}
                <MdCancel
                  style={{ marginLeft: "0.5rem", cursor: "pointer" }}
                  onClick={() => deleteTag(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              type="text"
              variant="outlined"
              value={tagInput}
              placeholder="Enter tags"
              disabled={!isEditable}
              onKeyDown={tagOnKeyDown}
              onChange={handleTagValueChange}
              size="small"
              fullWidth
            />
          </VariableField>
        </>
      )}


 <UserMessageCheckbox sendUserUpdate={sendUserUpdate} setSendUserUpdate={setSendUserUpdate} setUserMessage={setUserMessage} userMessage={userMessage} isEditable={isEditable}/>
 <UserCheckbox title={"Extract Complaint"} value={extractComplaint} setValue={setExtractComplaint} isEditable={isEditable}/>
 <UserCheckbox title={"Extract Compliment"} value={extractCompliment} setValue={setExtractCompliment} isEditable={isEditable} />
  </BaseConfiguration>
  );
};

// Exports:
export default memo(TicketConfiguration);